<template>
  <vs-popup
    classContent="popup-example"
    :title="title"
    :active.sync="activePopup"
  >
    <div class="body">
      <slot></slot>
    </div>

    <div class="actions" v-if="showActions">
      <vs-button class="float-right mb-3" v-if="onAccept" @click="accept">
        {{ lang.botMaker.accept[languageSelected] }}
      </vs-button>
      <vs-button
        type="flat"
        class="float-right mr-4 mb-3"
        color="danger"
        v-if="onCancel"
        @click="cancel"
      >
        {{ lang.botMaker.cancel[languageSelected] }}
      </vs-button>
    </div>
  </vs-popup>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'KonaPopup',
  props: {
    title: {
      type: String,
      default: ''
    },
    validate: Function,
    onAccept: Function,
    onCancel: Function,
    onClosePopup: Function,
    showActions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activePopup: false
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    openPopup() {
      this.activePopup = true
    },
    closePopup() {
      this.activePopup = false
      if (this.onClosePopup) {
        this.onClosePopup()
      }
    },
    cancel() {
      this.activePopup = false
      this.onCancel()
    },
    async accept() {
      let valid = true
      if (this.validate) {
        valid = await this.validate()
      }
      if (valid) {
        this.activePopup = false
        this.onAccept()
      }
    }
  },
  mounted() {
    const next = this.$children[0].$refs.btnclose
    if (this.onClosePopup) {
      next.$el.addEventListener('click', this.onClosePopup, false)
      this.$children[0].$on('close', () => {
        this.onClosePopup() // the active.sync (true false) value
      })
    }
  }
}
</script>
